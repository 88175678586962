.container{
    padding: 0rem 5rem;
}
.header{
    padding: 2rem 0rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 3rem;
}
.header_image .img{
    width: 300px;
}
.header_image .img img{
    width: 100%;
}
.header_image .title, .c_img .title, .topImg .title, .bottomImg .title{
    font-size: 0.9rem;
    padding: 0.5rem 0rem;
    opacity: 0.8;
    font-style: italic;
    color: #666666;
    font-weight: 400;
}
.header_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.header_text .subtitle p{
    font-weight: 400;
}
.releases, .buysell, .famous{
    padding: 2rem 0rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.releases_header{
    margin-bottom: 2rem;
}
.releases_header .subtitle p{
    font-weight: 400;
}

.releases_cards{
    width: 100%;
    display: flex;
    justify-content: left;
}
.releases_cards_inner{
    display: flex;
    flex-wrap: wrap;
    gap: 3.8rem;
    justify-content: left;
}
.card{
    max-width: 400px;
}
.c_header{
    margin-bottom: 1.5rem;
}
.c_text{
    font-weight: 400;
}
.c_header p{
    margin-bottom: 0rem;
    font-weight: bold;
}
.c_img{
    width: 100%;
}
.c_img img{
    width: 100%;
}
.c_link a{
    color: black;
    text-decoration: underline;
    font-weight: bold;
}
.b_header{
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
}
.b_header .text{
    margin-top: 25px;
}
.b_header .text p{
    margin-bottom: 0rem;
}
.b_header .text a{
    display: block;
    color: black;
    text-decoration: underline;
}
.b_text{
    font-weight: 400;
}
.famous{
    display: flex;
    justify-content: flex-end;
}
.famous_inner{
    display: flex;
    gap: 3rem;
}
.footer_text{
    text-align: right;
}
.footerImg{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.footerImg .title{
    text-align: right;
}
.bottomImg .img{
    width: 100px;
}
.bottomImg .img img{
    width: 100%;
}
.footer_text p{
    font-weight: 400;
}
@media screen and (max-width:1440px) {
    .releases_cards_inner{
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        justify-content: left;
    }
    .card{
        max-width: 300px;
    }
}
@media screen and (max-width:1024px) {
    .header{
        flex-direction: column;
        align-items: center;
        gap: 0rem;
    }
    .header_text{
        text-align: center;
    }
    .releases_header{
        text-align: center;
    }
    .b_text{
        text-align: center;
    }
    .famous{
        justify-content: center;
    }
    .famous_inner{
        flex-direction: column;
        gap: 0rem;
    }
    .footer_text{
        text-align: center;
    }
    .buysell {
        text-align: center; 
    }
    .footerImg{
        align-items: center;
    }
    .releases_cards_inner{
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        justify-content: center;
    }
    .card{
        max-width: 300px;
    }
}
@media screen and (max-width:768px) {
    .container{
        padding: 0rem 30px;
    }
    .buysell .title{
        text-align: center;
    }
    .b_header{
        flex-direction: column;
        align-items: center;
    }
    .b_header .text{
        margin-top: 0rem;
    }
}
@media screen and (max-width:520px){
    .b_header, .topImg{
        width: 100%;
    }
    .b_header .img, .topImg .img{
        width: 100%;
    }
    .b_header .img img, .topImg .img img{
        width: 100%;
    }
}
@media screen and (max-width:360px){
    .header_image, .header_image .img, .header_image .img img{
        width: 100%;
    }
}