@charset "utf-8";

/* Bootstrap customize and private css  */

/*------------------------------------------------------------------
[Table of contents]

1. Html / Body
2. Footer / Social Media Icons
3. Address / Google Maps / Bootstrap Modal / IFRAME
4. Menu / Mobile Menu 
5. Portfolio Content and Customizations / Single Page
6. Responsive Navigation
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[1. Html / Body / Banner]
*/
*{
    margin: 0;
    padding: 0;
    /* border: 1px solid red; */
}
html {
    position: relative;
    /* min-height: 100%; */
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
}

body {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 16px;
    margin-bottom: 60px;
    background: #f4f4f4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

header{
    padding:14px;
}

#contact-sidebar {
    height: -webkit-fill-available;
    height: -moz-available;
}

#side-banner {
    width: 100%;
    padding: 0;
}

.body-header {
    overflow-y: hidden;
}

.metamaskBannerClosed {
  margin-top: 0px;
}

/* Add margin on both side of bootstrap alert box to align to image tiles below */
.alert-info {
    margin-left: 10px;
    margin-right: 10px;
}

.transaction-button {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
}

.tx-input-field {
    margin-right: 5px;
}

.alert-heading {
    margin-top: 0px;
    margin-bottom: 0px
}

.dropdown-menu.cat{
    left:50%;
    right:50%;
    min-width:180px;
}

/* floating metamask banner */
#metamask-info-banner{
    position: fixed;
    /* background-color: #E27D7D; */
    /* background-color: #ffffff; */
    background-color: #e8e8e8;
    border: 2px solid #333;
    top:30px;
    left:0px;
    height:110px;
    width: 245px;
    z-index: 99;
}

a#metamask-address {
    color: #333; 
    /* color: #326f8e; */
    text-decoration: underline;
}

.info-banner-content {
    padding: 6px 6px;
    font-size: 14px;
}

.close:before {
  content: '✕';
}
.close {
  top: 0;
  right: 0;
  font-size: 14px;
}

/* #bannerimage {
  width: 100%;
  padding: 0px 0px;
  background-image: url(../images/comingsoon.png);
  height: 350px;
  background-position: center;
} */

/*------------------------------------------------------------------
[2. Footer / Social Media Icons]
*/

.util-spinner-img {
    height: 32px;
}

.footer {
    position: absolute;
    width: 100%;
    padding: 19px 0px;
    background: #fff;
    display: flex;
    align-items: center;
    bottom: 0;
}

.footer-small {
	color: #555;
    display: block;
    margin: 10px;
    margin-left: 25px;
    font-size: 16px;
}

.footer > .container {
    padding-right: 15px;
    padding-left: 15px;
}

.social-icons {
    float: right;
    padding: 0!important;
    list-style-type: none!important;
    margin: 1px 22px 0 auto;
}

.social-icons ul {
    padding: 0px;
    margin: 0px;
}

.social-icons li {
    float: left!important;
    margin-right: 2px!important;
    margin-bottom: 0px!important;
    padding: 0px!important;
    display: inline!important;
    width: auto!important;
}

.social-icons li a {
    transition: all 0.3s ease 0s;
    display: block!important;
    float: left!important;
    height: 100%!important;
    margin: 0!important;
    padding: 0!important;
    width: 40px!important;
    height: 40px!important;
    text-indent: -9999px!important;
}

.social-icons li a:hover {
    background-position: 0 -40px!important;
}

.social-icons li a {
    background-color: transparent;
}

/*------------------------------------------------------------------
[3. Address / Google Maps / Bootstrap Modal / IFRAME]
*/

iframe {
    width: 100%;
}

.modal-header {
    font-size: 16px;
}

.modal-content {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 0px;
}

.modal-body {
    padding: 0px;
}

address {
	color: #666;
    font-size: 16px;
    margin-top: 20px;
}

address strong {
	color: #1f1f1f;
}

address a {
	color: #666;
    text-decoration: none;
    transition: .3s;
}

address a:hover {
	color: #000;
    text-decoration: none;
}

address a i {
	color: #1f1f1f;
	font-size: 16px;
	transition: .3s;
}

address a i:hover {
	color: #000;
}

/*------------------------------------------------------------------
[4. Menu / Mobile Menu ]
*/

.mobile-br {
    display: none;
}

#filter-status-text {
    padding-left: 10px;
}

.filter-group {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.btn--primary:hover {
    background-color: #d4d4d4;
}

.btn-sm:hover {
    background-color: #d4d4d4;
    border-color: #8c8c8c;
    color: #8c8c8c;
    text-decoration: none;
}

.btn--primary:focus.active {
    box-shadow: inset 0 1px 2px rgba(0,0,0,.3), 0 0 0 2px rgba(52,152,219,.4);
}

.btn--primary:active {
    background-color: #d4d4d4;
}

.btn--primary:focus {
    box-shadow: 0 0 0 2px rgba(52,152,219,.4);
}

.btn-sm:focus.active {
    box-shadow: inset 0 1px 2px rgba(0,0,0,.3), 0 0 0 2px rgba(52,152,219,.4);
}

.btn-sm.active, .btn-sm:active {
    background-color: #d4d4d4;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
    color: #8c8c8c;
}

.btn-group .btn-sm:first-child {
    border-radius: 3px 0 0 3px;
    margin-right: -2px;
    margin-left: -2px;
}

.btn-group .btn-sm:last-child {
    border-radius: 0 3px 3px 0;
    line-height: 1em;
}

.btn-group .btn-sm:not(:first-child) {
    margin-left: 0px;
    margin-right: 0px;
    line-height: 1em;
    /*float: left;*/
}

/* .btn--primary {
    background-color: #ffffff;
    border-color: #8c8c8c;
    color: #8c8c8c;
    border-radius: 0;
} */

.list-group a:hover {
    background: none;
}

.list-group-item {
    background: transparent;
    border: 0px;
    font-size: 16px;
    color: #666;
    transition: .3s;
    padding: .25rem .5rem;
}

.list-group-item:hover {
	color: #000;
}

.menu {
    width: 175px;
    float: left;
    margin-left: 20px;
    margin-right: 15px;
    overflow-y: hidden;
    height: -moz-calc(100vh - 200px);
    height: -webkit-calc(100vh - 200px);
    height: calc(100vh - 200px);
}

.cbp-filter-item {
    cursor: pointer;
}

.hvr-underline-from-left {
    display: block;
    -webkit-transform: none;
    transform: none;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
}

.hvr-underline-from-left:before {
    background: #000;
    height: 1px;
    bottom: 2px;
    transition: 0.4s cubic-bezier(1, 0, 0, 1);
    transition-delay: .1s;
}

.hvr-underline-from-center:before {
	background: #1f1f1f;
	height: 2px;
	right: 51%;
}

li.hvr-underline-from-center:before {
    height: 1px;
}

.list-group .logo {
    margin-top: 10px;
    margin-bottom: 20px;
}

a.list-group-item {
    color: #666;
    transition: .3s;
}

a.list-group-item:hover {
    color: #000;
}

a.list-group-item:focus {
    background: transparent;
}

a:focus {
    outline: none;
}

/*Mobile Menu*/

.navbar-default .navbar-nav>li>a {
    color: #1f1f1f;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background-color: transparent;
}

.navbar-toggle {
    position: absolute;
    float: none;
    right: 0;
    padding: 7px 10px 9px 10px;
    margin: 0;
    background-color: transparent;
    background-image: none;
    border: 0;
}

.navbar-collapse {
    margin-top: 31px;
}

.navbar-collapse .navbar-nav {
    margin: 20px -15px;
}

.navbar-default {
    background-color: transparent;
    border: 0;
}

.navbar-nav>li>a {
    padding: 0px;
}

.nav>li>a {
    padding: 0px
}

.navbar-header img {
    cursor: pointer;
}

/*------------------------------------------------------------------
[5. Portfolio Content and Customizations / Single Page ]
*/

.portfolio-item {
    margin-bottom: 25px;
}

.cbp-caption-activeWrap {
    background: #000;
}

.portfolio-content .cbp-l-project-desc-title span,
.portfolio-content .cbp-l-project-details-title span {
    border-bottom-color: #1f1f1f;
}

.cbp-item .btn-group {
    width: 100%;
}

.cbp-item .btn {
    width: 50%;
    color: #fff;
    padding: 0;
    border: 0;
    margin: 0;
    transition: background .35s, color .2s;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    border-radius: 0;
    background: transparent;
    height: 47px;
    line-height: 46px;
}

.cbp-item .btn:hover {
    background: #313131;
    color: #dfdfdf;
}

.cbp-l-project-details .btn.focus,
.cbp-l-project-details .btn:focus,
.cbp-l-project-details .btn:hover {
    color: #fff;
}

.cbp-l-project-container {
    margin: 0px auto 0;
}

.cbp-item .btn-right {
    border-left: 1px solid #313131 !important;
}

.btn-group-sm>.btn, .btn-sm {
    border-radius: 15;
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
    margin-left: 0;
}

.cbp {
    margin-right: 10px;
}

.cbp-item-wrapper {
    padding: 5px 15px 30px 5px;
}

.cbp-slider .cbp-item-wrapper {
    padding: 0;
}

.cbp-slider.cbp {
    margin-right: 0;
} 

.cbp-item-wrap {
    background: #fff;
    transition: box-shadow .35s ease-out, transform .3s ease-out, opacity .2s ease-out;
    margin-left: 5px;
    margin-right: 10px;
}

.cbp-item-wrap:hover {
    box-shadow: 0 15px 20px rgba(0,0,0,0.2);
    transform: translate(0, -4px);
    opacity: 1 !important;
}

.cbp .cbp-item {
    overflow: visible;
}

.cbp-popup-singlePage-counter {
    font: 400 14px/30px "Open Sans Condensed",sans-serif;
}

.cbp-l-project-details .btn {
    font: 400 12px/17px "Open Sans Condensed", sans-serif;
    padding: 7px 15px;
    border-radius: 0;
    background: #1f1f1f;
    transition: .3s;
}

.cbp-l-project-details .btn:hover {
    color: #fff;
    background: #222;
}

.cbp-l-project-title,
.cbp-l-project-desc-title,
.cbp-l-project-related-title,
.cbp-l-grid-projects-title,
.cbp-l-grid-projects-desc {
    color: #1f1f1f;
}

.cbp-l-project-related-link {
    transition: .1s;
}

.cbp-popup-singlePage .cbp-popup-navigation-wrap {
    background-color: #1f1f1f;
}

.cbp-l-project-details-list strong {
    color: #555;
}

.cbp-l-project-subtitle {
    font: 400 16px/21px 'Open Sans Condensed', sans-serif;
}

.cbp-l-project-desc-title span,
.cbp-l-project-details-title span {
    border-bottom: 2px solid #333;
}

.cbp-l-grid-projects-desc {
    height: 34px;
}

.cbp-l-loadMore-button {
    visibility: hidden;
    opacity: 0;
    transition:visibility 0s linear 0.5s,opacity 0.5s linear;
}

.cbp-l-loadMore-button .cbp-l-loadMore-button-link,
.cbp-l-loadMore-button .cbp-l-loadMore-link {
    margin-top: 15px;
}

/*------------------------------------------------------------------
[6. Responsive Navigation ]
*/

@media screen and (max-width: 767px) {
    /* show filter buttons on new line */
    .mobile-br {
        display: inline;
    }

    /* Hide My Cats filter and ETH withdraw Btn */
    #filterStsMyCatsBtn {
        display:none;
    }

    #withdraw-ETH-btn {
        display:none;
    }

    header{
        padding:14px;
    }

	.navbar {
		margin-top: 20px;
		margin-bottom: 0;
        padding: 0 30px;
	}

    .cbp {
        margin-right: 0;
    }

	.portfolio-content .cbp-l-loadMore-button {
		margin-bottom: 0;
	}

    .cbp-item-wrapper {
        padding-right: 0;
    }

	.footer {
		position: relative;
		bottom: -60px;
	}

	.social-icons {
		display: flex;
		justify-content: center;
		float: none;
		margin-top: 40px;
		margin-right: -5px;
	}

}

/*-------------------------------------------------------------------
Some CSS-code
------------------------------------------------------------------*/

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}

.row {
    margin: 0px;
}

body > .container {
    padding: 60px 15px 0;
}

.container-fluid {
    width: 100%;
    padding: 0;
    margin-top: 10px;
}

footer .container-fluid {
    margin-top: 0;
}

.container .text-muted {
    margin: 20px 0;
}

.progress {
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
}

.pror-bor-black {
    border: 1px solid rgba(0, 0, 0, 1);
}

.pror-bor-gey {
    border: 1px solid #cacaca;
}

.pror-bor-red {
    border: 1px solid #d9534f
}

.progress-bar-black {
    background: rgba(0, 0, 0, 1);
}

.progress-bar-gey {
    background-color: #cacaca;
}

.glyphicon {
    vertical-align: middle;
    line-height: 17px;
    min-height: 20px;
    font-size: 10px;
    margin-right: 1px;
}

.glyphicon-resize-full {
    margin-right: 3px;
}

i.glyphicon-map-marker {
    cursor: pointer;
    font-size: 16px;
}

.bs-docs-section .h1,
.bs-docs-section .h2,
.bs-docs-section .h3,
.bs-docs-section h1,
.bs-docs-section h2,
.bs-docs-section h3 {
    margin-top: 30px;
}

.bs-docs-section .h4,
.bs-docs-section .h5,
.bs-docs-section .h6,
.bs-docs-section h4,
.bs-docs-section h5,
.bs-docs-section h6 {
    margin-top: 20px;
}

.cat-main-img {
    width: 40%;
}

.info-diagram-img {
    width: 85%;
}

.us-img {
    max-width: 60%;
}

.logo {
  font-size: 64px;
  color: white;
  text-align: center;
  font-weight: bolder;
}
.heading2 {
  font-size: 24px;
  color: white;
  text-align: center;
  font-weight: bolder;

}

#landing{
    background-image: radial-gradient(ellipse farthest-corner at 50% 0,#3b3b3b 0,#000 95%);
    font-weight: lighter;
}

.btn-last {
  border-radius: 0px 3px 3px 0px;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

#wrap-successful {
  margin: 30px 0px 15px 0px;
}

#wrap-steps {
  margin: 15px 0px;
}

#wrap-modal-body {
  padding: 15px;
}

#utilities-section {
  margin-bottom: 20px;
}

.done-label {
  background-color: forestgreen;
  /* padding: 10px;
  font-size: large; */
  padding: .25rem .5rem;
  font-size: .875rem;
  font-weight: 600;
  margin: 0% 20%;
}

.wrap-tx-spinner {
  max-height: 48px;
}

.width-100 {
  width: 100%;
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
      max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 100%;
  }
}

#mobile-menu {
  margin-bottom: 15px;
}

#mobile-info-section {
  margin-top: 40px;
  color: black;
}

#mobile-info-section > a {
  color: black;
}

#network-green-led {
  max-height: 1em;
}

.wallet-label-area {
  display: flex;
}

.no-cats-label {
  font-size: x-large;
  font-weight: 600;
  margin: 25px;
  color: grey;
}

#web3-loading-spinner {
  max-height: 36px;
  padding: 0px 5px;
}

#filter-loading-area {
  width: max-content;
  display: inline-block;
  padding-left: 10px;
}

.m-top-10pct {
  margin-top: 10%;
}

.step-title {
  font-size: 1.5em;
  font-weight: 600;
}

.step-text {
  margin-bottom: 10px; 
}

@media screen and (max-width:425px) {
    .step-text {
        line-height: 1.25em;
    }
}

@media screen and (max-width:425px) {
    #wrap-modal-body > .row > div > p {
        line-height: 1.35em;
    }
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary:disabled {
  color: #fff;
  background-color: #001225;
  border-color: #005abb;
}

a {
  color: #337ab7;
}
.cards_container{
    width: 100%;
    box-sizing: border-box;
    padding: 0px 30px;
    display: grid;
    gap: 2rem;
    /* grid-template-columns: repeat(6, minmax(130px, 1fr)); */
}

@media screen and (min-width:1024px) and (max-width:1440px) {
    .cards_container{
        grid-template-columns: repeat(6, minmax(130px, 1fr));
    }
}

@media screen and (min-width:1441px) {
    .cards_container{
        grid-template-columns: repeat(8, minmax(130px, 1fr));
    }
}

@media screen and (min-width:425px) {
    .cat-card {
        background-color: inherit;
        width: 100%;
        transition: box-shadow .35s ease-out;
        transform: scale(0);
        opacity: 0;
        animation: cat-card-anim 0.5s ease-in-out 1 forwards;
        max-width: 190px;
    }
    .cat-card-explore {
        background-color: inherit;
        width: 100%;
        transition: box-shadow .35s ease-out;
        transform: scale(0);
        opacity: 0;
        animation: cat-card-anim 0.5s ease-in-out 1 forwards;
        max-width: 190px;
    }
}

.cat-card {
    background-color: inherit;
    width: 100%;
    transition: box-shadow .35s ease-out;
    transform: scale(0);
    opacity: 0;
    animation: cat-card-anim 0.5s ease-in-out 1 forwards;
}

.cat-card-explore {
    background-color: inherit;
    width: 100%;
    transition: box-shadow .35s ease-out;
    transform: scale(0);
    opacity: 0;
    animation: cat-card-anim 0.5s ease-in-out 1 forwards;
}

@keyframes cat-card-anim {
    from{
        transform: scale(0);
        opacity: 0;
    }
    to{
        transform: scale(1);
        opacity: 1;
    }
}
.cat-card:hover{
    box-shadow: 0 15px 20px rgb(0 0 0 / 20%);
    transform: translate(0, -4px);
}
.cat-card-explore:hover{
    box-shadow: 0 15px 20px rgb(0 0 0 / 20%);
    transform: translate(0, -4px);
    cursor: pointer;
}


.cat-details {
    flex-shrink: 0;
    width: 100%;
    background-color: white;
}


.cat-img{
    width: 100%;
}
.cat-img img{
    width: 100%;
    object-fit: cover;
}
.cat-action-btn {
    border: 0px;
    text-decoration: none;
    color: #337ab7;
    background-color: transparent;
}
.cat-info-area {
    text-align: center;
    padding-bottom: 8px;
}
.cat-label {
    margin-top: 15px;
}
.cat-button{
    text-align: center;
    height: 35px;
}
#filter-btn{
    box-shadow: none !important;
    outline: none !important;
    font-size: 12px !important;
    padding: 5px 10px !important;
}
.filter-btn-inner{
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
}
#wrap-modal-body .row .offset-lg-4{
    margin-left: 0px;
}
@media screen and (max-width:1023px) {
    .filter-btn-container{
        width: 100%;
    }
    .cards_container{
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
}

.vis-hidden {
  visibility: hidden;
}
/* 
.menu-title-text {
    color: #000000;
    line-height: 1.2em;
    margin-top: 8px;
    width: max-content;
} */

.social-area {
    display: inline-block;
}

.social-icon-col {
    float: left;
    width: 33.33%;
    padding: 12px;
    max-width: 45px;
}

.social-icon-img {
    width: 100%;
}

.licence-text {
    line-height: 1.3em;
}

/* Gamemaker related styles */
canvas {
    image-rendering: optimizeSpeed;
    -webkit-interpolation-mode: nearest-neighbor;
    -ms-touch-action: none;
    touch-action: none;
    margin: 0px;
    padding: 0px;
    border: 0px;
    height: -moz-calc(100vh - 200px);
    height: -webkit-calc(100vh - 200px);
    height: calc(100vh - 200px);
    /* height: 80vh; */
    /* height: -webkit-fill-available; */
    /* width: -webkit-fill-available; */
    width: 100%;
}
:-webkit-full-screen #canvas {
     width: 100%;
     height: 100%;
}
div.gm4html5_div_class
{
  margin: 0px;
  padding: 0px;
  border: 0px;
}
iframe#game_frame {
    height: 75vh;
}
/* START - Login Dialog Box */
div.gm4html5_login
{
     padding: 20px;
     position: absolute;
     border: solid 2px #000000;
     background-color: #404040;
     color:#00ff00;
     border-radius: 15px;
     box-shadow: #101010 20px 20px 40px;
}
div.gm4html5_cancel_button
{
     float: right;
}
div.gm4html5_login_button
{
     float: left;
}
div.gm4html5_login_header
{
     text-align: center;
}
/* END - Login Dialog Box */
:-webkit-full-screen {
   width: 100%;
   height: 100%;
}

@media screen and (max-width: 767px) {
    canvas {
        height: -moz-calc(100vh - 260px);
        height: -webkit-calc(100vh - 260px);
        height: calc(100vh - 260px);
    }
}

a.menu-feature-highlight1 {
    color: #ED1FC5;
}

a.menu-feature-highlight1:hover {
    color: #ED1FC5;
}

.explore-card-body {
    padding: 0.25rem 0.75rem
}
.cat-card-name {
    font-weight: 300;
    line-height: 1rem;
    height: 48px;
}
.cat-card-img {
    width: 100%;
    padding: 4px;
}
.cat-card-details {
    flex-shrink: 0;
    width: 100%;
    background-color: white;
}
.cat-card-label {
    margin-top: 0px;
}
.cat-detail-area {
    position: relative;
    margin: 0 auto;
    max-width: 1024px;
    vertical-align: top;
    width: 94%;
    text-align: center;
}
.cat-detail-name {
    font: 600 42px/46px "Open Sans Condensed", sans-serif;
    letter-spacing: 2px;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
}
.cat-detail-main-img {
    max-height: 300px;
}
.cat-detail-action-area {
    color: #787878;
    font: 400 14px/21px "Open Sans Condensed", sans-serif;
    margin: 0 auto 50px;
    max-width: 500px;
    text-align: center;
}
.cat-detail-action-area > div > p {
    font: 400 16px/21px 'Open Sans Condensed', sans-serif;
}

.cat-detail-action-area > .btn {
    color: white;
}

.cat-detail-action-area > .btn-primary:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.cat-detail-action-area > .btn-primary:active, .cat-detail-action-area > .btn-primary:focus {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}


.cat-detail-info-area {
    margin: 0px auto 0;
    overflow: hidden;
    clear: both;
    text-align: left;
}
.cat-detail-description {
    float: left;
    width: 62%;
}
.cat-detail-traits {
    float: right;
    width: 38%;
    padding-left: 60px;
    margin-bottom: 15px;
}
.cat-desc-title {
    border-bottom: 1px solid #cdcdcd;
    margin-bottom: 22px;
    color: #1f1f1f;
}
.cat-desc-title > span {
    border-bottom: 2px solid #333;
    display: inline-block;
    margin: 0 0 -1px;
    font: 400 16px/36px "Open Sans Condensed", sans-serif;
    padding: 0 5px 0 0;
    text-transform: uppercase;
    font-weight: 600;
}
.cat-desc-text {
    font: 500 16px/20px "Open Sans Condensed", sans-serif;
    color: #222;
    margin-bottom: 20px;
}
.cat-traits-title {
    border-bottom: 1px solid #cdcdcd;
    margin-bottom: 19px;
    color: #444;
}
.cat-traits-title > span {
    border-bottom: 2px solid #333;
    display: inline-block;
    margin: 0 0 -1px;
    font: 400 16px/36px "Open Sans Condensed", sans-serif;
    padding: 0 5px 0 0;
    text-transform: uppercase;
    font-weight: 600;
}
.cat-traits-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.cat-traits-list > li {
    border-bottom: 1px dotted #DFDFDF;
    padding: inherit;
    color: #222;
    font: 400 14px/30px "Open Sans Condensed", sans-serif;
}
.cat-traits-list > li:last-child {
    border: none;
}
.cat-traits-list > li > strong {
    display: inline-block;
    color: #555;
    font-weight: 600;
    min-width: 100px;
}
.cat-detail-container {
    margin-top: 20px;
}
@media screen and (max-width: 767px) {
    .cat-detail-description {
        width: 100%;
    }
    .cat-detail-traits {
        width: 100%;
        padding: 0;
    }
}

.tx-input-field {
    margin-right: 5px;
}

.transaction-button {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    padding: 0.1rem .5rem;
}

.cat-util-function {
    margin: 2px 0px;
}

.inline {
    display:inline-block
}

.goto-wrapper-button {
    margin: 10px 0px;
}

.detail-with-name {
    margin-top: 4px;
}

.detail-wout-name {
    height: 100%;
    padding-top: 10px;
}